import { PDFViewer } from "@react-pdf/renderer";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import MyDocument from "./MyDocument";

const Invoice = () => {
  const [invoiceData, setInvoiceData] = React.useState(null);

  React.useEffect(() => {
    document.title = "Sample Request";
  }, []);

  const location = useLocation();

  const getInfo = localStorage.getItem("dm-userInfo")
    ? JSON.parse(localStorage.getItem("dm-userInfo"))
    : null;

  const getData = localStorage.getItem("sample_request")
    ? JSON.parse(localStorage.getItem("sample_request"))
    : null;

  React.useEffect(() => {
    if (location) {
      const getData = localStorage.getItem("sample_request")
        ? JSON.parse(localStorage.getItem("sample_request"))
        : null;

      setInvoiceData(getData);
    }
  }, [location]);

  console.log(getData);

  return (
    <PDFViewer
      style={{
        minHeight: "calc(100vh - 10px)",
        minWidth: "calc(100vw - 12px)",
      }}
      showToolbar={true}
    >
      <MyDocument
        invoiceData={invoiceData ? [invoiceData] : null}
        getInfo={getInfo}
      />
    </PDFViewer>
  );
};

export default Invoice;
