import axios from "axios";

const axiosClient = axios.create({
  // baseURL: "http://dmdb.clonestudiobd.com/api/",
  // baseURL: "https://step-up-api.clonestudiobd.com/api/",
  // baseURL: "https://st-shirt-api.sgwicusbd.com/api/",
  baseURL: "https://st-shirt-api.sgwicusbd.com/api/",
});

export default axiosClient;
