import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";

// Create styles
const Quixote = ({ invoiceData, getInfo }) => {
  const tableBodyData = invoiceData && invoiceData?.length ? invoiceData : [];

  const totalOrderQty =
    tableBodyData &&
    tableBodyData?.reduce(
      (totalValue, currentValue) => totalValue + Number(currentValue?.quantity),
      0
    );

  const today = new Date();
  const defaultValue = new Date(today).toISOString().split("T")[0]; // yyyy/mm/dd
  return (
    <Document>
      <Page orientation="portrait" size="A4" style={styles.body} wrap>
        {/* Invoice Image & Header PART */}
        <View wrap={false}>
          <View
            style={{
              borderBottom: "2px solid black",
              paddingBottom: 5,
            }}
          >
            <View style={{ margin: "auto" }}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: 900,
                  fontFamily: "Times",
                }}
              >
                BANDO GLOBAL CO., LTD
              </Text>
            </View>
            <View style={{ margin: "auto" }}>
              <Text style={{ fontSize: 10 }}>
                Dhaka Export Processing Zone, Plot# 73-76, Ganakbari, Dhaka,
                Savar.
              </Text>
            </View>
          </View>

          <View
            style={{
              height: "auto",
              marginTop: "2px",
            }}
          >
            <Text
              style={{
                fontSize: "13px",
                fontWeight: "bold",
                fontFamily: "Times",
                textAlign: "center",
              }}
            >
              Sample Request
            </Text>
            <Text
              style={{
                width: 110,
                textAlign: "center",
                margin: "auto",
                borderBottom: "1px solid black",
              }}
            ></Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <View>
              <Text style={{ fontSize: "9px" }}>
                <Text style={{ fontWeight: 900, fontFamily: "Times" }}>
                  Print Date:
                </Text>{" "}
                {new Date(defaultValue).toLocaleDateString(["es-CL", "id"])}
              </Text>
              <Text style={{ fontSize: "9px" }}>
                <Text style={{ fontWeight: 900, fontFamily: "Times" }}>
                  Print By:
                </Text>{" "}
                {getInfo?.first_name + ` ` + getInfo?.last_name}
              </Text>
            </View>
          </View>
        </View>

        {/* Header Info */}
        <View
          style={{
            height: "30px",
            marginTop: "20px",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <View style={{}}>
            <Text
              style={{
                fontSize: "10px",
                fontFamily: "Times",
                fontWeight: 900,
                paddingBottom: 2,
              }}
            >
              CAD IN-CHARGE
            </Text>

            <Text
              style={{
                fontSize: "10px",
                fontFamily: "Times",
                fontWeight: 900,
                paddingBottom: 2,
              }}
            >
              MR IN-CHARGE
            </Text>
          </View>
          <View>
            <Text style={{ fontSize: "10px", paddingBottom: 2 }}>
              :{" "}
              {tableBodyData && tableBodyData.length
                ? tableBodyData[0]?.["sample_man_name"]
                : null}
            </Text>
            <Text style={{ fontSize: "10px", paddingBottom: 2 }}>
              :{" "}
              {tableBodyData && tableBodyData.length
                ? tableBodyData[0]?.["merchant_name"]
                : null}
            </Text>
          </View>
          <View>
            <Text
              style={{
                fontSize: "10px",
                fontFamily: "Times",
                fontWeight: 900,
                paddingBottom: 2,
              }}
            >
              Request Date
            </Text>
            <Text
              style={{
                fontSize: "10px",
                fontFamily: "Times",
                fontWeight: 900,
                paddingBottom: 2,
              }}
            >
              Delivery Date
            </Text>
          </View>
          <View>
            <Text style={{ fontSize: "10px", paddingBottom: 2 }}>
              :{" "}
              {tableBodyData && tableBodyData.length
                ? new Date(
                    tableBodyData[0]?.["document_date"]
                  ).toLocaleDateString(["es-CL", "id"])
                : null}
            </Text>
            <Text style={{ fontSize: "10px", paddingBottom: 2 }}>
              :{" "}
              {tableBodyData && tableBodyData.length
                ? new Date(
                    tableBodyData[0]?.["delivery_date"]
                  ).toLocaleDateString(["es-CL", "id"])
                : null}
            </Text>
          </View>
        </View>

        {/* TABLE PART */}
        <View style={{ marginTop: "20px" }}>
          <View
            style={{
              border: "1px solid black",
              flexDirection: "row",
              width: "100%",
              backgroundColor: "#d3cfcf",
            }}
            fixed
          >
            <Text
              style={{
                padding: "4px 3px",
                width: "16.66%",
                fontSize: "9px",
                fontFamily: "Times",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              BUYER
            </Text>

            <Text
              style={{
                padding: "4px 3px",
                width: "16.66%",
                fontSize: "9px",
                fontFamily: "Times",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              SAMPLE TYPE
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "9px",
                width: "16.66%",
                fontFamily: "Times",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              STYLE
            </Text>

            <Text
              style={{
                padding: "4px 3px",
                fontSize: "9px",
                width: "16.66%",
                fontFamily: "Times",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              COLOR
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "9px",
                width: "16.66%",
                fontFamily: "Times",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              SIZE
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "9px",
                width: "16.66%",
                fontFamily: "Times",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Quantity
            </Text>
          </View>
          <View>
            {tableBodyData &&
              tableBodyData.length &&
              tableBodyData.map((item, index) => (
                <View
                  key={index}
                  wrap={false}
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  <Text
                    style={{
                      padding: "3px",
                      fontSize: "8px",
                      height: "auto",
                      width: "16.66%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.buyer_name}
                  </Text>
                  <Text
                    style={{
                      padding: "3px",
                      fontSize: "8px",
                      height: "auto",
                      width: "16.66%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.sample_type}
                  </Text>
                  <Text
                    style={{
                      padding: "3px",
                      fontSize: "8px",
                      height: "auto",
                      width: "16.66%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.style}
                  </Text>

                  <Text
                    style={{
                      padding: "3px",
                      fontSize: "8px",
                      height: "auto",
                      width: "16.66%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.color_name}
                  </Text>
                  <Text
                    style={{
                      padding: "3px",
                      fontSize: "8px",
                      height: "auto",
                      width: "16.66%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.size_set}
                  </Text>

                  <Text
                    style={{
                      padding: "3px",
                      fontSize: "8px",
                      height: "auto",
                      width: "16.66%",
                      textAlign: "center",
                    }}
                  >
                    {item?.quantity}
                  </Text>
                </View>
              ))}
            <View
              wrap={false}
              style={{
                flexDirection: "row",
                width: "100%",
                border: "1px solid black",
              }}
            >
              <Text
                style={{
                  padding: "3px",
                  fontSize: "9px",
                  height: "auto",
                  fontFamily: "Times",
                  fontWeight: "bold",
                  width: "83.34%",
                  borderRight: "1px solid black",
                  textAlign: "right",
                }}
              >
                TOTAL
              </Text>

              <Text
                style={{
                  padding: "3px",
                  fontSize: "9px",
                  height: "auto",
                  fontFamily: "Times",
                  fontWeight: "bold",
                  width: "16.66%",
                  textAlign: "center",
                }}
              >
                {totalOrderQty}
              </Text>
            </View>
          </View>
          <View style={{ border: "1px solid black" }}>
            <Text style={{ fontSize: 10, padding: 5 }}>DESCRIPTIONS</Text>
            <View style={{ borderTop: "1px solid black", height: "auto" }}>
              <Text style={{ fontSize: 10, padding: 10 }}>
                {tableBodyData.length && tableBodyData?.[0]?.description
                  ? tableBodyData?.[0]?.description
                  : " "}
              </Text>
            </View>
          </View>
          <View style={{ border: "1px solid black" }}>
            <Text style={{ fontSize: 10, padding: 5 }}>COMMENTS</Text>
            <View style={{ borderTop: "1px solid black", height: "auto" }}>
              <Text style={{ fontSize: 10, padding: 10 }}>
                {tableBodyData.length &&
                tableBodyData?.[0]?.comment_by_sample_man
                  ? tableBodyData?.[0]?.comment_by_sample_man
                  : " "}
              </Text>
            </View>
          </View>
        </View>

        {/* Signature part */}
        <View
          wrap={false}
          style={{
            flexDirection: "row",
            justifyContent: "space-around",
            marginTop: "60px",
          }}
        >
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Prepared By
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Checked By
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Approved By
              </Text>
            </View>
          </View>
        </View>

        {/* TOTAL CALCULATE PART */}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

Font.register({
  family: "Helvetica",
  fonts: [
    {
      src: "/assets/fonts/Helvetica.ttf",
      fontWeight: "normal",
    },
    {
      src: "/assets/fonts/Helvetica Bold.ttf",
      fontWeight: "bold",
    },
  ],
});

Font.register({
  family: "Times",
  fonts: [
    {
      src: "/assets/fonts/Times-Bold.ttf",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  imageContent: {
    paddingLeft: "30px",
    paddingRight: "30px",
    // marginTop: "-20px",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 60,
    paddingHorizontal: 20,
    fontFamily: "Helvetica",
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    // fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    // fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default Quixote;
